import * as cocoSsd from '@tensorflow-models/coco-ssd';
import '@tensorflow/tfjs';
import { Memory } from '../memory';

export const useModel = () => {
    const loadModel = async () => {
        if (Memory.model) {
            return null;
        }
        const loadedModel = await cocoSsd.load();
        Memory.model = loadedModel;
    };

    return {
        model: Memory.model,
        loadModel
    }
}