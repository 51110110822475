import { FC } from "react";

import "./skeleton-loader.scss";

interface ISkeleton {
  width?: number;
  height?: number;
  borderRadius?: number;
}

export const Skeleton: FC<ISkeleton> = ({ height, width, borderRadius }) => {
  return (
    <div className="skeleton_container" style={{ width: `${width}px` }}>
      <span
        className="skeleton-loader-light"
        style={{ height: `${height}px`, borderRadius: `${borderRadius}px` }}
      />
    </div>
  );
};
