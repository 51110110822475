import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { FaceLandmarkerResult } from '@mediapipe/tasks-vision';
import Button from '@mui/material/Button';
import {v4} from 'uuid'
import { useFace } from './hooks';
import { UserList } from './user-list';
import { EventState, QrState, SelectedDeviceIdState, SelectedSessionIdState, VideoHeightState } from './states';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import usePeerConnection from './hooks/event-connect-rtc';
import { Memory } from './memory';
import { CameraSelector } from './devices';
import { EventMatching } from './events-match';
import { SessionModal } from './session-info';
import { EventModalWithIframe, HOST_URL } from './event-modal';
import { PendingUsers } from './pending-users';
import axios from 'axios';
import { DateTime } from 'luxon'

const webcamClass = '_webcam'
const canvasClass = '_output_canvas'

export const getDateTime=(date: string)=>{
  const formattedDate = DateTime.fromISO(date).toFormat('EEEE, LLLL dd · h:mma');
  return formattedDate;
}

export function Event() {
  const [label, setLabel] = useState("ENABLE WEBCAM");
  const {start} = useFace();
  const [webcamOpen, setWebcamOpen] = useState(false);
  const [open, setOpen] = useState(false); // State to manage modal visibility
  const [instanceId, setInstanceId] = useState(v4());
	const { createPeerConnection } = usePeerConnection();
  const selectedDeviceId = useRecoilValue(SelectedDeviceIdState);
  const [selectedSessionId, setSelectedSessionId] = useRecoilState(SelectedSessionIdState);
  const setQrs = useSetRecoilState(QrState)
  const setEvent = useSetRecoilState(EventState);

  const videoHeight = useRecoilValue(VideoHeightState);
  
  const [isSessionLoading, setIsSessionLoading] = useState(false);

  const onResults = useCallback((results?: FaceLandmarkerResult) => {
    // 
  }, [])

  const onCam = useCallback(() => {
    const videoBlendShapes = document.getElementById("video-blend-shapes");
    const video = document.getElementById(webcamClass) as HTMLVideoElement;
    const canvasElement = document.getElementById(
      canvasClass
    ) as HTMLCanvasElement;
    setLabel("ENABLE WEBCAM")
    start({
        video,
        canvas: canvasElement,
        blendShapes: videoBlendShapes,
        deviceId: selectedDeviceId,
        onResults,
        numFaces: 10,
        palm: false,
    }).then(() => {
      setWebcamOpen(true);
    });
  }, [onResults, selectedDeviceId, start])

  const onClick = useCallback(async() => {
    if (!Memory.connectionMade) {
      createPeerConnection();
    }
    const url = `${HOST_URL}/pipelines`;
    axios.get(`${url}/events/${Memory.eventId}?type=event`).then(({data}) => {
      const eventData = data?.data;
      if (eventData) {
        setEvent(eventData);
      }
      console.log('sadasd', eventData);
      setQrs(eventData?.qrIds ?? []);
    }).catch(err => {
      console.error("event err", err)
    });
    onCam();
  }, [createPeerConnection, onCam, setEvent, setQrs])

  const Buttons = useCallback(() => {
    return (
      <Grid container>
        <Grid item sm={4}>
        <Button onClick={() => {
          setOpen(true)
          setInstanceId(v4())
        }} className="mdc-button mdc-button--raised" variant="outlined">
            <span className="mdc-button__ripple"></span>
            <span className="mdc-button__label">Enroll</span>
        </Button>
        </Grid>

        <Grid item sm={4}>
          <CameraSelector />
        </Grid>
        <Grid item sm={4}>
        {isSessionLoading ? 
          <CircularProgress style={{float: "right"}} /> 
          : 
          <Button style={{float: "right"}} onClick={onClick} id="webcamButton" className="mdc-button mdc-button--raised" variant="contained">
              <span className="mdc-button__ripple"></span>
              <span className="mdc-button__label">{label}</span>
          </Button>
        }
        </Grid>
      </Grid>
    )
  }, [isSessionLoading, label, onClick]);

  const Blends = useCallback(() => {
    return (
      <>
        <div className="blend-shapes">
          <ul className="blend-shapes-list" id="video-blend-shapes"></ul>
        </div>
      </>
    )
  }, [])

  const Camera = useCallback(() => {
    return (
      <>
        <div>
          <div id="liveView" className="videoView">
            <div style={{
              position: "relative"
            }}>
              <video id={webcamClass} style={{
                position: "absolute"
              }} autoPlay playsInline></video>
              <canvas className={canvasClass} id={canvasClass} style={{
                position: 'absolute',
                left: 0,
                top: 0
              }}></canvas>
            </div>
          </div>

          <div>
            <img alt='' id="palm" src="" style={{display: 'none'}} />
          </div>
        </div>
      </>
    )
  }, [])

  return (
    <>
    { open ? <EventModalWithIframe instanceId={instanceId} setOpen={setOpen} open={open} /> : null }
    { 
       selectedSessionId ? <SessionModal 
          setOpen={() => setSelectedSessionId(null)}
          open={!!selectedSessionId} 
          sessionId={selectedSessionId} 
        /> : null
      }
    <Grid container spacing={2}>
      <Grid className='left-section' item sm={6}>
        <Grid minHeight={videoHeight + 100} item>
          <Buttons />
          <div>
            <Camera />
          </div>
        </Grid>
        {
          webcamOpen ?  
          <Grid item>
            <EventMatching />
            <PendingUsers />
          </Grid> : null
        }
      </Grid>
      <Grid item sm={6}>
        <Grid item>
          <Blends />
        </Grid>
        <Grid item>
          <UserList />
        </Grid>
      </Grid>
    </Grid>
    </>
  );
}
