import React from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import PlaceIcon from '@mui/icons-material/Place';
import FlagIcon from '@mui/icons-material/Flag';
import DeviceIcon from '@mui/icons-material/Devices';
import { SessionDetailState } from './states';
import { useRecoilValue } from 'recoil';

export function UserCard() {
  const sessionDetail = useRecoilValue(SessionDetailState);
  const defaultValue = '--';

  const {metadata} = sessionDetail ?? {}
  const {deviceInfo, sessionMetadata} = metadata ?? {}
  const {userAddress, ipAddress = defaultValue, systemData} = sessionMetadata ?? {}
  const {name= defaultValue, email = defaultValue} = sessionDetail?.userInfo ?? {};

  // const {browser, os} = userAgent ?? {};
  const {name: browserName = defaultValue} = systemData?.browser ?? {};
  const {name: osName = defaultValue} = systemData?.os ?? {};

  const city = userAddress?.city ?? defaultValue;
  const region = userAddress?.region ?? defaultValue;
  const appleId = sessionDetail ? deviceInfo?.appleId ?? "N/A" : defaultValue;
  const country = userAddress?.country ?? defaultValue;
  const deviceId = deviceInfo?.deviceId ? "N/A" : defaultValue;

  return (
    <Box sx={{ maxWidth: 800 }}>
      <Typography variant="h6" color="text.primary" sx={{ fontSize: '1rem', paddingBottom: '8px' }}>  {/* Smaller font and padding for title */}
        User Details
      </Typography>
      <Grid container spacing={2}>
      <Grid item sm={6}>
      <List dense>  {/* Dense list for reduced spacing between list items */}
        <ListItem sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}>  {/* Reduced vertical padding for list items */}
          <ListItemIcon>
            <DeviceIcon fontSize="small" />  {/* Smaller icons */}
          </ListItemIcon>
          <ListItemText style={{margin: 2}} primary="Device OS" secondary={osName} primaryTypographyProps={{ fontSize: '0.875rem' }} secondaryTypographyProps={{ fontSize: '0.75rem' }} />
        </ListItem>
        <ListItem  sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}> 
          <ListItemIcon>
            <PublicIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText style={{margin: 2}} primary="User IP" secondary={ipAddress} primaryTypographyProps={{ fontSize: '0.875rem' }} secondaryTypographyProps={{ fontSize: '0.75rem' }} />
        </ListItem>
        <ListItem  sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}> 
          <ListItemIcon>
            <PlaceIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText style={{margin: 2}} primary="Region" secondary={region} primaryTypographyProps={{ fontSize: '0.875rem' }} secondaryTypographyProps={{ fontSize: '0.75rem' }} />
        </ListItem>
        <ListItem  sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}> 
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText style={{margin: 2}} primary="Name" secondary={name} primaryTypographyProps={{ fontSize: '0.875rem' }} secondaryTypographyProps={{ fontSize: '0.75rem' }} />
        </ListItem>
        <ListItem  sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}> 
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText style={{margin: 2}} primary="Apple ID" secondary={appleId} primaryTypographyProps={{ fontSize: '0.875rem' }} secondaryTypographyProps={{ fontSize: '0.75rem' }} />
        </ListItem>
      </List>
      </Grid>
      <Grid item xs={6}>
      <List dense>
        <ListItem  sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}> 
            <ListItemIcon>
              <BrowserUpdatedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText style={{margin: 2}} primary="Browser" secondary={browserName} primaryTypographyProps={{ fontSize: '0.875rem' }} secondaryTypographyProps={{ fontSize: '0.75rem' }} />
          </ListItem>
          <ListItem  sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}> 
            <ListItemIcon>
              <LocationCityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText style={{margin: 2}} primary="City" secondary={city} primaryTypographyProps={{ fontSize: '0.875rem' }} secondaryTypographyProps={{ fontSize: '0.75rem' }} />
          </ListItem>
          <ListItem  sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}> 
            <ListItemIcon>
              <FlagIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText style={{margin: 2}} primary="Country Code" secondary={country} primaryTypographyProps={{ fontSize: '0.875rem' }} secondaryTypographyProps={{ fontSize: '0.75rem' }} />
          </ListItem>
          <ListItem  sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}> 
            <ListItemIcon>
              <EmailIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText style={{margin: 2}} primary="Email" secondary={email} primaryTypographyProps={{ fontSize: '0.875rem' }} secondaryTypographyProps={{ fontSize: '0.75rem' }} />
          </ListItem>
          <ListItem  sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}> 
            <ListItemIcon>
              <DeviceIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText style={{margin: 2}} primary="Device Id" secondary={deviceId} primaryTypographyProps={{ fontSize: '0.875rem' }} secondaryTypographyProps={{ fontSize: '0.75rem' }} />
          </ListItem>
      </List>
      </Grid>
      </Grid>
    </Box>
  );
}
