import React, { useCallback, useState } from 'react';
import { useFace } from './hooks';
import { FaceLandmarkerResult } from '@mediapipe/tasks-vision';

export function Home() {
  const [number, setNumber] = useState('')
  const [label, setLabel] = useState("ENABLE WEBCAM");
  const {start} = useFace();

  const onResults = useCallback((results?: FaceLandmarkerResult) => {
    // 
  }, [])

  const onClick = useCallback(() => {
    const videoBlendShapes = document.getElementById("video-blend-shapes");
    const video = document.getElementById("webcam") as HTMLVideoElement;
    const canvasElement = document.getElementById(
      "output_canvas"
    ) as HTMLCanvasElement;
    setLabel("ENABLE WEBCAM")
    start({
        video,
        canvas: canvasElement,
        blendShapes: videoBlendShapes,
        onResults
    });
  }, [start, onResults])

  return (
      <div className="App">
        <div id="liveView" className="videoView">
          <button onClick={onClick} id="webcamButton" className="mdc-button mdc-button--raised">
            <span className="mdc-button__ripple"></span>
            <span className="mdc-button__label">{label}</span>
          </button>
          <div style={{
            position: "relative"
          }}>
            <video id="webcam" style={{
              position: "absolute"
            }} autoPlay playsInline></video>
            <canvas className="output_canvas" id="output_canvas" style={{
              position: 'absolute',
              left: 0,
              top: 0
            }}></canvas>
          </div>
        </div>

        <div>
          <img alt='' id="palm" src="" style={{display: 'none'}} />
        </div>

       <div className="blend-shapes">
        <ul className="blend-shapes-list" id="video-blend-shapes"></ul>
      </div>
      {localStorage.getItem("register") ? <div id="register-form">
        <div>Register</div>
        <input placeholder='number' onChange={(e) => setNumber(e.target.value)} />
        <button onClick={() => {
          localStorage.setItem("phone", number)
        }}>Submit</button>
        <button onClick={() => {
          localStorage.removeItem("phone")
        }}>Reset</button>
      </div> : null}
    </div>
  );
}
