import { useCallback } from "react";
import { Memory } from "../memory";

const socketUrl = `wss://simplici-webrtc.satschel.com/${Memory.sessionId}`;
let socket = new WebSocket(socketUrl)

export const useSocket = () => {
    const emit = useCallback((eventName: string, data: any) => {
        const states: number[] = [socket.CLOSED, socket.CLOSING, socket.CONNECTING]
        const isSocketDisconnecd = states.includes(socket.readyState)
        if (isSocketDisconnecd) {
            socket = new WebSocket(socketUrl)
        } else {
            socket.send(JSON.stringify(data))
        }
    }, []);

    return {
        emit,
        // socket,
    }
}