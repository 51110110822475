import React, { FC, useState } from "react";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";

interface Props {
  open: boolean;
  onClose: any;
  onSubmit: any;
}

const PhoneNumberModal: FC<Props> = ({ open, onClose, onSubmit }) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleSubmit = () => {
    onSubmit(phoneNumber);
  };

  const handleCancel = () => {
    setPhoneNumber("");
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      aria-labelledby="phone-number-modal-title"
      aria-describedby="phone-number-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="phone-number-modal-title" variant="h6" component="h2">
          Enter Phone Number
        </Typography>
        <TextField
          fullWidth
          label="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          margin="normal"
          variant="outlined"
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PhoneNumberModal;
