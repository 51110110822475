import type { IPendingProfile } from './types';

import './pending-profile.scss';

export const PendingProfile = ({
	img,
	handleClick,
	className = '',
}: IPendingProfile) => {
	// Return early if no image is provided
	if (!img) return null;

	return (
		<div
			className={`d-flex gp-8 direction-column pending-profile-wrapper ${className}`}
		>
			<img
				className="pending-profile-wrapper__img"
				src={img}
				alt="Pending Profile" // Add alt attribute for accessibility
			/>
			<div className="d-flex gp-8 direction-column">
				<button className="approve-btn" onClick={handleClick}>
					Approve
				</button>
				<button className="deny-btn">Deny</button>
			</div>
		</div>
	);
};
