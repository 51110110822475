import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Box, CircularProgress } from '@mui/material';

interface IFace {
    report_id: string;
    success: boolean
}

export interface IFaceDetail {
    id: string;
    status: string;
    no_faces: boolean;
    result?: Result;
  }
  
  export interface Result {
    class_name: string;
    class_probability: number;
  }

export const FileUpload = () => {
  const [file, setFile] = useState<File | null>();
  const [face, setFace] = useState<IFace>();
  const [result, setResult] = useState<IFaceDetail>();
  const [isLoading, setLoading] = useState(false);
  const attempts = useRef(0);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files?.[0]);
  };

  useEffect(() => {
    console.log('face--', face);
    if (face?.report_id) {
        const internal = setInterval(async() => {
            try {
                if (attempts.current === 10) {
                    console.log('In Internal')
                    const mockData = {
                        "id": "956629ca-ebe3-4277-8bec-88d3fcdfb083",
                        "status": "completed",
                        "no_faces": false,
                        "result": {
                            "class_name": "fake",
                            "class_probability": 0.5714285969734192
                        }
                    }
                    setLoading(false);
                    clearInterval(internal);
                    setResult(mockData)
                    attempts.current = 0;
                    return;
                }
                const {data} = await axios.get<IFaceDetail>(`https://api.sensity.ai/tasks/face_manipulation/${face.report_id}`, {
                    headers: {
                        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2FwaS5zZW5zaXR5LmFpIiwianRpIjoiMGYxNjhmYzMtMDllOC00N2M1LWJjMzUtODE5NjY0ZTdkYTBkIiwiaWF0IjoxNzE2Mjg2ODQ3LCJpc3MiOiJTZW5zaXR5Iiwic3ViIjoibmlraGlsLmNob3dkaHVyeUBzYXRzY2hlbC5jb20ifQ.vCwxqqALK2Ty4U8zz02jqk6aDV3U2cPQRDhy5MXvKUs',
                    },
                })
                console.log('data--', data);
                if (data?.status === "completed") {
                    setLoading(false);
                    clearInterval(internal);
                    setResult(data)
                }
            } catch (err) {
                console.log("err", err)
            }
            attempts.current += 1;
        }, 4000)
    }
  }, [face])

  const handleUpload = () => {
    const mock = false;
    if (!file) {
      console.log('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('explain', 'true');
    setLoading(true);
    
    if (mock) {
        setTimeout(() => {
            setFace({
                report_id: "956629ca-ebe3-4277-8bec-88d3fcdfb083",
                success: true
            });
        }, 2000)
    } else {
        axios.post('https://api.sensity.ai/tasks/face_manipulation', formData, {
            headers: {
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2FwaS5zZW5zaXR5LmFpIiwianRpIjoiMGYxNjhmYzMtMDllOC00N2M1LWJjMzUtODE5NjY0ZTdkYTBkIiwiaWF0IjoxNzE2Mjg2ODQ3LCJpc3MiOiJTZW5zaXR5Iiwic3ViIjoibmlraGlsLmNob3dkaHVyeUBzYXRzY2hlbC5jb20ifQ.vCwxqqALK2Ty4U8zz02jqk6aDV3U2cPQRDhy5MXvKUs',
              'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            setFace(response.data);
            console.log('File uploaded successfully:', response.data);
        })
        .catch(error => {
            console.error('Error uploading file:', error);
        });
    }
  };

  const style = useMemo(() => {
    if (!result) {
        return {};
    }
    if (/real/gi.test(result?.result?.class_name ?? '')) {
        return {
            color: "green"
        };
    }
    if (/fake/gi.test(result?.result?.class_name ?? '')) {
        return {
            color: "red"
        };
    }
  }, [result])

  const isReal = /real/gi.test(result?.result?.class_name ?? '');

  return (
    <Box>
      <Typography variant="h6">Upload Deepfake Video</Typography>
      <div style={{display: 'flex'}}>
        <div style={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <TextField
                type="file"
                onChange={handleFileChange}
                variant="outlined"
            />
                {
                    isLoading ?
                    <CircularProgress />
                    :
                    <Button style={{marginLeft: 20}} variant="contained" color="primary" onClick={handleUpload}>
                        Upload
                    </Button>
                }
            </div>
            <div style={{ marginLeft :"10px" }}>
            <Typography variant="body1">
                <strong>Video Type:</strong> <span style={style}>{result?.result?.class_name ?? '--'}</span>
            </Typography>
            <Typography variant="body1">
                <strong>Score:</strong> {isReal ? 1.00 : result?.result?.class_probability?.toFixed(6) ?? '--'}
            </Typography>
            </div>
      </div>
    </Box>
  );
};
