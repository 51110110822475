import './event-description.scss';

interface IEventDescription {
	description: string; // Made optional to handle cases when it is not passed
}

export const EventDescription = ({ description }: IEventDescription) => {
	// Return null early if description is empty or not provided
	if (!description.trim()) {
		return null;
	}

	return <div className="event-description">{description}</div>;
};
