import React, { useMemo } from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemText } from '@mui/material';
import { EventState, SessionInfoState } from './states';
import { useRecoilValue } from 'recoil';
import { getDateTime } from './events';

export function PendingUsers() {
  const event = useRecoilValue(EventState);
  const sessions = useRecoilValue(SessionInfoState);

  console.log('sessions', sessions);
  console.log('event', event);
  const filteredUsers = useMemo(() => {
    return event?.users.filter((user: any) => !sessions[user.sessionId]) ?? []
  }, [event?.users, sessions]);

  console.log('filteredUsers', filteredUsers)
  const users = useMemo(() => {
    return filteredUsers.map((user: any) => {
      return (
        <ListItem style={{
          marginTop: "10px",
          border: "1px solid black",
          padding: "10px"
        }} key={user.sessionId} sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}>  {/* Reduced vertical padding for list items */}
          <img style={{
              borderRadius: "20px",
              width: "100px",
              marginRight: "10px"
          }} alt='selfie' src={user.image} />
          <ListItemText primary={`Enrolled at: `} secondary={getDateTime(user.createdAt)}  secondaryTypographyProps={{ fontSize: '0.75rem' }}  primaryTypographyProps={{ fontSize: '0.75rem' }} />
          <ListItemText primary={`Status: `} secondary={user.status} secondaryTypographyProps={{ fontSize: '0.75rem', color: /approve/gi.test(user.status) ? "green" : /reject/gi.test(user.status) ? 'red' : 'gray' }}  primaryTypographyProps={{ fontSize: '0.75rem' }} />
          { 
            user.status !== "PENDING" ?
              <ListItemText primary={`${user.status} at: `} secondary={getDateTime(user.changedAt)} secondaryTypographyProps={{ fontSize: '0.75rem' }}  primaryTypographyProps={{ fontSize: '0.75rem' }} />
            : null
          }
        </ListItem>
      )
    })
  }, [filteredUsers])

  return (
    <Box sx={{ maxWidth: 800 }}>
      <Typography variant="h5" color="text.primary" sx={{ fontSize: '1rem', paddingBottom: '8px' }}>  {/* Smaller font and padding for title */}
        Pending Users
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={12}>
        <List style={{overflow: "scroll", maxHeight: "200px", padding: "5px"}} dense>
          {users}
        </List>
        </Grid>
      </Grid>
    </Box>
  );
}
