import { ReactNode, KeyboardEvent } from "react";

import classNames from "classnames";
import ReactModal, { Styles } from "react-modal";

import "./modal.scss";

type Props = {
  className?: string;
  isOpen: boolean;
  closeModal?: () => void;
  overlayStyles?: Styles;
  modalName: string;
  children: ReactNode;
  showCloseBtn?: boolean;
  title?: string | JSX.Element;
  isStopOutsideClick?: boolean;
  closeOnEscBtn?: boolean;
  ariaHideApp?: any;
  optionalClassName?: string;
  showHeader?: boolean;
  titleWithCss?: string;
  isCloseButtonSticky?: boolean;
};

export const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(44, 48, 57, 0.8)",
    zIndex: 100,
    overflowY: "auto",
  },
};

/**
 * Modal Component
 * @param {string} [Props.className] - Additional class names for styling
 * @param {boolean} Props.isOpen - Flag to control the modal's visibility
 * @param {() => void} [Props.closeModal] - Callback function to close the modal
 * @param {Styles} [Props.overlayStyles] - Custom overlay styles
 * @param {string} Props.modalName - Unique identifier for the modal
 * @param {ReactNode} Props.children - Content to be rendered inside the modal
 * @param {boolean} [Props.showCloseBtn=true] - Flag to show or hide the close button
 * @param {string | JSX.Element} [Props.title] - Title content for the modal
 * @param {boolean} [Props.isStopOutsideClick=true] - Flag to control whether clicking outside the modal closes it
 * @param {boolean} [Props.closeOnEscBtn=true] - Flag to control whether pressing the Esc key closes the modal
 * @param {*} [Props.ariaHideApp] - ARIA hide app property
 * @param {string} [Props.optionalClassName] - Additional class name for optional styling
 * @param {boolean} [Props.showHeader=true] - Flag to show or hide the modal header
 * @param {boolean} [Props.isCloseButtonSticky] - Additional class names for styling
 */

const Modal = ({
  className,
  isOpen,
  closeModal = () => ({}),
  overlayStyles,
  modalName,
  children,
  showCloseBtn = true,
  title,
  isStopOutsideClick = true,
  closeOnEscBtn = true,
  optionalClassName,
  showHeader = true,
  titleWithCss,
  isCloseButtonSticky,
}: Props) => {
  const classes = classNames("Modal", className);
  const closeBtnClasses = classNames(
    "Modal__closeButton",
    isCloseButtonSticky && "Modal__stickyCloseButton"
  );

  const handleClose = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      closeModal();
    }
  };

  const combinedStyles = {
    ...styles,
    overlay: { ...styles.overlay, ...overlayStyles?.overlay },
  };

  return (
    <ReactModal
      closeTimeoutMS={500}
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel={modalName}
      style={combinedStyles as Styles}
      className={classes}
      shouldCloseOnOverlayClick={isStopOutsideClick}
      shouldCloseOnEsc={closeOnEscBtn}
      parentSelector={() => document.querySelector("body") as any}
      ariaHideApp={false}
    >
      <div
        className={`Modal__wrapper ${
          optionalClassName ? optionalClassName : ""
        }`}
      >
        <div style={{ display: showHeader ? "contents" : "none" }}>
          <div className={titleWithCss}>{title}</div>
          {showCloseBtn && (
            <div
              className={closeBtnClasses}
              onClick={closeModal}
              onKeyPress={handleClose}
              role="button"
              tabIndex={0}
            >
              <i className="ri-close-line Modal__closeIcon" />
            </div>
          )}
        </div>
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
