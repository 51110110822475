import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from 'components';

import { EventHeader } from '../event-header';
import { PROFILE_TABS, TAG_MAP } from '../../constants';
import { HeaderOptions } from '../header-options';
import { ProfileSection, ProfileSectionSkelton } from '../profile/profile';
import { EventState, IEvent, SessionInfoState } from 'states';
import { useRecoilValue } from 'recoil';

// Custom hook for debouncing
export const useDebounce = (value: string, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(value);
	const [isDebouncing, setIsDebouncing] = useState(false);

	useEffect(() => {
		setIsDebouncing(true);

		const handler = setTimeout(() => {
			setDebouncedValue(value);
			setIsDebouncing(false);
		}, delay);

		return () => clearTimeout(handler);
	}, [value, delay]);

	return { debouncedValue, isDebouncing };
};

export const AttendeeLists = () => {
	const [currentTab, setCurrentTab] = useState<ProfileTabValue>('allInvitee');
	const [searchTerm, setSearchTerm] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const event = useRecoilValue(EventState);
  	const sessions = useRecoilValue(SessionInfoState);

	const users = useMemo(() => {
		const eventUsers: IEvent['users'] = JSON.parse(JSON.stringify(event?.users ?? []));
		switch (currentTab) {
			case 'allInvitee': {
				return eventUsers.sort((a,b) => {
					return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
				});
			}
			case 'attended': {
				const attendedUsers = eventUsers.filter((user) => user.attendedAt);
				return attendedUsers.sort((a,b) => {
					return new Date(b.attendedAt!).getTime() - new Date(a.attendedAt!).getTime()
				});
			}
			case 'noShow': {
				return eventUsers.filter((user) => !user.attendedAt && !sessions[user.sessionId]);
			}
			default: {
				return eventUsers;
			}
		}
	}, [event, sessions, currentTab]);

	const eventUsers = useMemo<IEventUser[]>(() => {
		const eventUsers: IEventUser[] = users.map(user => {
			const {_id, image,name, phone, email, status, enrolledAt, attendedAt, createdAt} = user;
			const ticketNumber = ''

			let date = enrolledAt ?? createdAt;
			if (currentTab === 'attended' && attendedAt) {
				date = attendedAt
			}
			const tag = TAG_MAP[status] ?? status
			const eventUser: IEventUser = {
				_id,
				email,
				img: image,
				name,
				phone,
				ticketNumber,
				date,
				tags: [tag, 'Club Seats'],
			}
			return eventUser;
		})

		return eventUsers
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTab, users, event])

	const [filteredResults, setFilteredResults] = useState(eventUsers);

	useEffect(() => {
		if(!filteredResults.length && eventUsers.length) {
			setFilteredResults(eventUsers)
		}
	}, [eventUsers])

	const { debouncedValue, isDebouncing } = useDebounce(searchTerm, 300);

	useEffect(() => {
		// Early return if search term is empty
		if (!debouncedValue && !searchTerm) {
			setFilteredResults(eventUsers);
			setIsLoading(false);
			return;
		}

		setIsLoading(true);

		// Filter the dummy users based on the debounced search term
		const results = eventUsers.filter(person => {
			const searchLower = debouncedValue.toLowerCase();
			return (
				person.name.toLowerCase().includes(searchLower) ||
				person.email.toLowerCase().includes(searchLower) ||
				person.ticketNumber.toLowerCase().includes(searchLower)
			);
		});

		setFilteredResults(results);
		setIsLoading(false);
	}, [debouncedValue, eventUsers, searchTerm]);

	const handleChange = useCallback((e: ProfileTab<ProfileTabValue>) => {
		setCurrentTab(e.value);
	}, []);

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};

	return (
		<Fragment>
			<EventHeader label="Attendee List" className="pl-16 pr-16" />

			<div className="pl-16 pr-16 br-bottom pb-8">
				<Input
					label={''}
					inputType="text"
					placeholder="Search"
					handleChange={handleSearchChange} // Moved to separate function for readability
					value={searchTerm}
					suffixIcon="ri-search-line"
				/>
			</div>

			<HeaderOptions
				options={PROFILE_TABS}
				active={currentTab}
				handleChange={handleChange}
			/>

			<div
				style={{
					height: 'calc(100vh - 277px)',
					overflow: 'auto', // Changed to 'auto' for better scroll behavior
				}}
			>
				{isDebouncing || isLoading ? (
					<div className="">
						{[...Array(4)].map((_, index) => (
							<ProfileSectionSkelton
								className="br-bottom profile-padding"
								key={`${index.toString()}`}
							/>
						))}
					</div>
				) : (
					filteredResults.map(el => (
						<ProfileSection
							className="br-bottom profile-padding"
							key={el._id}
							type={currentTab === 'attended' ? "Attended" : "Enrolled"}
							{...el}
						/>
					))
				)}
			</div>
		</Fragment>
	);
};
