import { useCallback, useState } from "react";
import "./event-camera.scss";
import { useFace } from "hooks";
import { Memory } from "memory";
import usePeerConnection from "hooks/event-connect-rtc";
import { HOST_URL } from "event-modal";
import axios from "axios";
import { EventState, SelectedDeviceIdState, VideoHeightState } from "states";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FaceLandmarkerResult } from "@mediapipe/tasks-vision";

import { EventMatching } from "./events-match";
import { CameraSelector } from "devices";
import { Button } from "components";

export const EventCamera = () => {
  const [isCameraOn, setCameraOn] = useState(false);
  const setEvent = useSetRecoilState(EventState);
  const { start } = useFace();
  const { createPeerConnection } = usePeerConnection();
  const videoHeight = useRecoilValue(VideoHeightState);
  const selectedDeviceId = useRecoilValue(SelectedDeviceIdState);

  const onResults = useCallback((results?: FaceLandmarkerResult) => {
    //
  }, []);

  // Classes for styling the canvas and webcam elements
  const canvasClass = "_output_canvas";
  const webcamClass = "_webcam";

  // Callback function to start the webcam and face detection

  const onCam = useCallback(() => {
    const videoBlendShapes = document.getElementById("video-blend-shapes");
    const video = document.getElementById(webcamClass) as HTMLVideoElement;
    const canvasElement = document.getElementById(
      canvasClass
    ) as HTMLCanvasElement;

    start({
      video,
      canvas: canvasElement,
      blendShapes: videoBlendShapes,
      deviceId: selectedDeviceId,
      onResults,
      numFaces: 10,
      palm: false,
    }).then(() => {
      setCameraOn(true);
      //   setWebcamOpen(true);
    });
  }, [onResults, selectedDeviceId, start]);

  const onClick = useCallback(async () => {
    if (!Memory.connectionMade) {
      createPeerConnection();
    }
    const url = `${HOST_URL}/pipelines`;
    axios
      .get(`${url}/events/${Memory.eventId}?type=event`)
      .then(({ data }) => {
        const eventData = data?.data;
        if (eventData) {
          setEvent(eventData);
        }
        console.log("sadasd", eventData);
        //   setQrs(eventData?.qrIds ?? []);
      })
      .catch((err) => {
        console.error("event err", err);
      });
    onCam();
  }, [createPeerConnection, onCam, setEvent]);

  const Camera = useCallback(() => {
    return (
      <>
        <div>
          <div id="liveView" className="videoView">
            <div
              style={{
                position: "relative",
              }}
            >
              <video
                id={webcamClass}
                style={{
                  position: "absolute",
                }}
                autoPlay
                playsInline
              ></video>
              <canvas
                className={canvasClass}
                id={canvasClass}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              ></canvas>
            </div>
          </div>
        </div>
      </>
    );
  }, []);

  return (
    <>
      <div
        style={{
          minHeight: videoHeight + 20,
        }}
      >
        <div>
          <Camera />
        </div>
      </div>
      <div
        style={{
          minWidth: "800px",
          padding: "0px 16px",
        }}
      >
        {isCameraOn ? (
          <EventMatching />
        ) : (
          <div className="center-align">
            {/* Camera selector component to choose between available cameras */}
            <div className=" d-flex align-item-center direction-column space-around gp-8 p-16  w-480">
              <CameraSelector />
              {/* Button to trigger the onCam function */}
              <Button
                handleClick={onClick}
                label={"Enable Webcam"} // Dynamically updating the button label
                type="button__filled button__filled--primary button__large w-100"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
