import './header-options.scss';

// Define the props interface for HeaderOptions
interface IHeaderOptions {
	options: ProfileTab<ProfileTabValue>[]; // Array of tab options with type safety
	handleChange: (e: ProfileTab<ProfileTabValue>) => void; // Function to handle tab change
	active: ProfileTabValue; // Current active tab value
}

export const HeaderOptions = ({
	options,
	handleChange,
	active,
}: IHeaderOptions) => {
	// If options are not an array, return an empty fragment to avoid rendering
	if (!Array.isArray(options) || options.length === 0) {
		return null; // Return empty JSX instead of an empty fragment for better performance
	}

	return (
		<div className="event-profile__header">
			{options.map(el => (
				<div
					key={el._id} // Use value as unique key for performance optimization
					className={`event-profile__header_text ${
						el.value === active ? 'event-profile__header_text__active' : ''
					}`} // Apply active class based on condition
					onClick={() => handleChange(el)} // Inline event handler to change tab
				>
					{el.label} {/* Display the tab label */}
				</div>
			))}
		</div>
	);
};
