import React, { useCallback, useMemo, useState } from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemText, Button } from '@mui/material';
import { QrState, SelectedSessionIdState, SessionInfoState } from './states';
import { useRecoilState, useRecoilValue } from 'recoil';
import { HOST_URL } from './event-modal';
import axios from 'axios';
import { getDateTime } from './events';

/**
 * Filters an array of objects to return only unique objects based on a specified key.
 * 
 * @param {Array} arr - The array of objects to filter.
 * @param {string} key - The key to check for uniqueness.
 * @returns {Array} - An array of unique objects.
 */
function getUniqueList(arr: any[], key: string) {
  const uniqueObjects = new Map();

  arr.forEach((item) => {
    // Use the key value as the map key to ensure uniqueness
    if (!uniqueObjects.has(item[key])) {
      uniqueObjects.set(item[key], item);
    }
  });

  return Array.from(uniqueObjects.values());
}

/**
 * Filters an array of objects to remove duplicates and keep only non-duplicated objects based on a specified key.
 * 
 * @param {Array} arr - The array of objects to filter.
 * @param {string} key - The key to check for duplicates.
 * @returns {Array} - An array of non-duplicated objects.
 */
function removeDuplicates(arr: any[], key: string) {
  const countMap = new Map();

  // Count occurrences of each key value
  arr.forEach(item => {
    const keyValue = item[key];
    countMap.set(keyValue, (countMap.get(keyValue) || 0) + 1);
  });

  // Filter to keep only items that appear once
  return arr.filter(item => countMap.get(item[key]) === 1);
}

function keepOneDuplicate(arr: any[], key: string) {
  const seen = new Set();
  const result: any[] = [];

  arr.forEach(item => {
    const keyValue = item[key];
    if (!seen.has(keyValue)) {
      result.push(item);
      seen.add(keyValue);
    }
  });

  return result;
}

export function UserList() {
  const sessions = useRecoilValue(SessionInfoState);
  const [selectedSessionId, setSelectedSessionId] = useRecoilState(SelectedSessionIdState);
  const qrs = useRecoilValue(QrState);
  const [sessionStatus, serSessionStatus] = useState<any>({});

  const updateEvent = useCallback(async (sessionId: string, status: string) => {
    try {
      const eventId = qrs?.[0]?._id;
      // SET V2
      const HOST = `${HOST_URL}/pipelines`
      const {data: eventData} = await axios.get(`${HOST}/events/${eventId}`);
      console.log('eventData', eventData);
      const users = eventData?.data?.users ?? [];
      const foundUser = users.find((user: any) => user.sessionId === sessionId)
      console.log('foundUser', foundUser);
  
      if (!foundUser) {
        console.error("user not found");
        return;
      }
  
      console.log('users', users);

      foundUser.status = status;
      const changedAt = new Date().toISOString();
      foundUser.changedAt  = changedAt
      serSessionStatus((prev: any) => ({...prev, [sessionId]: {status, changedAt}}));
      const {data} = await axios.patch(`${HOST}/events/${eventId}`, {users});
      console.log("data", data)
    } catch(err) {
      console.log("err", err);
    }
  }, [qrs])

  console.log('sessionStatus',sessionStatus);

  const users = useMemo(() => {
    return Object.keys(sessions).map(sessionId => {
      const session = sessions[sessionId];
      const status = sessionStatus[session.sessionId]?.status ?? session.status;
      const changedAt = sessionStatus[session.sessionId]?.changedAt ?? session.changedAt;
      
      return (
        <ListItem style={{
          marginTop: "10px",
          border: "1px solid black",
          padding: "10px"
        }} key={session._id} sx={{ padding: '0px 0', '& .MuiListItemIcon-root': { minWidth: 32 } }}>  {/* Reduced vertical padding for list items */}
          <img style={{
              borderRadius: "20px",
              width: "100px",
              marginRight: "10px"
          }} alt='selfie' src={session.image} />
          <ListItemText style={{margin: 2}} primary={session.name} secondary={`${session.phone} <> ${session.email}`} primaryTypographyProps={{ fontSize: '0.875rem' }} secondaryTypographyProps={{ fontSize: '0.75rem' }} />
          {
            session.status !== "PENDING" ||
            sessionStatus[session.sessionId] ? 
            <>
              <ListItemText primary={`${status} at: `} secondary={getDateTime(changedAt)}  secondaryTypographyProps={{ fontSize: '0.75rem' }}  primaryTypographyProps={{ fontSize: '0.75rem' }} />
              <Button disabled style={{marginRight: "5px"}} variant='outlined' onClick={() => updateEvent(session.sessionId, "APPROVED")}>{status}</Button>
            </>
            :
            <>
              <Button color="success" style={{marginRight: "5px"}} variant='outlined' onClick={() => updateEvent(session.sessionId, "APPROVED")}>Approve</Button>
              <Button color="error"  variant='outlined' onClick={() => updateEvent(session.sessionId, "REJECTED")}>Reject</Button>
            </>
          }
          <Button style={{marginLeft: "5px"}} variant='outlined' onClick={() => setSelectedSessionId(session.sessionId)}>View</Button>
        </ListItem>
      )
    })
  }, [sessionStatus, sessions, setSelectedSessionId, updateEvent])

  return (
    <Box sx={{ maxWidth: 800 }}>
      <Typography variant="h6" color="text.primary" sx={{ fontSize: '1rem', paddingBottom: '8px' }}>  {/* Smaller font and padding for title */}
        Users
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={12}>
        <List style={{overflow: "scroll", maxHeight: "400px", padding: "5px"}} dense>
          {users}
        </List>
        </Grid>
      </Grid>
    </Box>
  );
}
