import { v4 } from "uuid";
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import { ISession } from "states";

export class Memory {
    static sessionId: string = v4()
    static connectionMade = false
    static attended: Record<string, boolean> = {};
    static sessionVudeoUrl = '';
    static frames: number | null = null
    static frames_limit: number | null = null
    static match: boolean;
    static timeout: number | null = null
    static useSocket: boolean = /true/gi.test(localStorage.getItem("socket") ?? '');
    static model: cocoSsd.ObjectDetection | null = null
    static webrtcConnected: boolean = false;
    static faceScore: number = 0
    static selfieBinary: ArrayBuffer
    static detection = {
        externalDevice: false,
        isPersonScoreLow: false
    }
    static eventId: string;
    static sessions: Record<string, ISession> = {}
    static unknownSessions: Record<string, boolean> = {}
}