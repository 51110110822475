import type {
  IEventTag,
  IProfileSectionSkelton,
  IProfileSection,
} from "./types";

import { Skeleton } from "components";

import { getDateTime, TAG_CSS } from "../../constants";
import { EventDescription } from "../event-description";
import { EventTitle } from "../event-title";

import "./profile.scss";
import { useMemo } from "react";

const EventTag = ({ name }: IEventTag) => {
  if (!name) {
    return <></>;
  }
  const { color, background } = TAG_CSS[name.toLowerCase()] ?? {};
  return (
    <div
      className="event-profile-tag"
      style={{
        color,
        background,
      }}
    >
      {name}
    </div>
  );
};

export const ProfileSectionSkelton = ({
  className = "",
}: IProfileSectionSkelton) => {
  return (
    <div className={`d-flex align-item-center gp-8 ${className}`}>
      <Skeleton height={108} width={80} borderRadius={4} />
      <div className={`d-flex gp-4 direction-column `}>
        <Skeleton width={120} height={20} borderRadius={4} />
        <Skeleton width={220} height={20} borderRadius={4} />
        <Skeleton width={170} height={20} borderRadius={4} />
        <div className="d-flex align-item-center gp-8">
          <Skeleton width={55} height={20} borderRadius={4} />
          <Skeleton width={55} height={20} borderRadius={4} />
          <Skeleton width={55} height={20} borderRadius={4} />
        </div>
      </div>
    </div>
  );
};

export const ProfileSection = ({
  className = "",
  name = "N/A",
  email = "N/A",
  img,
  phone = "N/A",
  tags = [],
  isSpoof = false,
  similarity = null,
  date = undefined,
  type = ''
}: IProfileSection) => {

  const faceStyle = useMemo(() => {
    if (similarity == null) {
      return {};
    }

    if (isSpoof === true) {
      return {
        color: "red",
      };
    }

    if (similarity < 50) {
      return {
        color: "red",
      };
    } else if (similarity > 50 && similarity < 80) {
      return {
        color: "#FFBF00",
      };
    } else if (similarity > 80) {
      return {
        color: "green",
      };
    }
    return {};
  }, [similarity, isSpoof]);

  return (
    <div className={`d-flex gp-8 ${className} ${isSpoof ? 'spoof' : ''}`}>
      <img className="profile-body-img" src={img} />
      <div>
        <EventTitle title={name} />
        <EventDescription description={email} />
        <EventDescription description={phone} />
        <div className="d-flex align-item-center gp-8">
          {tags.map((el: string, index: number) => (
            <EventTag name={el} key={email + index.toString() + el} />
          ))}
        </div>
        {
          similarity ? <span style={faceStyle}>{similarity?.toFixed(2)}%</span> : null
        }
        <div><span style={{fontSize: 11}}><b>{type ? `${type} on:` : ''}</b> {date ? getDateTime(date) : '-'}</span></div>
      </div>
    </div>
  );
};
