import { IButton as Props } from ".";

import "./button.scss";

/**
 * Button component
 *
 * @param {string | JSX.Element } Props.label - The label text for the button
 * @param {MouseEventHandler<HTMLButtonElement>} Props.handleClick - Callback function triggered on button click
 * @param {JSX.Element | any} Props.loader - Loader component to display while the button is in a loading state
 * @param {string} Props.type - The type of button (e.g., "button__filled--primary", "button__filled--secondary")
 * @param {string} Props.icon - CSS class for an icon to be displayed on the button
 * @param {string} Props.height - The height of the button
 * @param {boolean} Props.disabled - Flag to indicate whether the button is disabled
 * @param {string} Props.buttonType - The HTML button type attribute ("button", "submit", or "reset")
 * @param {string} Props.prefixIcon - CSS class for an icon to be displayed before the label
 * @param {string} Props.minHeight - The minimum height of the button
 * @param {string} Props.width - The width of the button
 * @param {string | undefined} Props.id - The id of the button
 */

export const Button = ({
  label,
  handleClick,
  loader,
  type,
  icon,
  height,
  disabled,
  buttonType,
  prefixIcon,
  minHeight,
  width,
  id = "",
}: Props) => {
  return (
    <button
      className={`button ${type} ${disabled ? "button--disabled" : ""}`}
      onClick={handleClick}
      style={{ minHeight, height, width }}
      disabled={disabled}
      type={buttonType}
      id={id}
    >
      {prefixIcon && <i className={prefixIcon} />}
      <div className="button-label-txt">{label}</div>
      {loader}
      {icon && <i className={icon} />}
    </button>
  );
};
