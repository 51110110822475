class FaceMetaInfo {
  static value = {
    code: "",
    hint: "",
    score: 100,
  };

  static readonly THRESHOLD = {
    FACE_MATCH: 70,
    LIVENESS: 0.4,
  }

  static hintObj = {
    "move closer": "MOVE CLOSER",
    "check ligting": "CHECK LIGNTING",
  };

  static TYPES = {
    s_spoof: "s_spoof",
    s_genuine: 's_genuine'
  };

  static codeObj = {
    s_spoof: "SPOOF",
    // s_genuine: "REAL",
    s_genuine: "",
  };

  static updateValue(
    key: "code" | "hint" | "score",
    newValue: string | number
  ) {
    if (key === "score") {
      FaceMetaInfo.value[key] = newValue as number;
    } else if (key === "hint") {
      const value = newValue as keyof typeof FaceMetaInfo.hintObj;
      FaceMetaInfo.value[key] = FaceMetaInfo.hintObj[value] || "";
    } else {
      const value = newValue as keyof typeof FaceMetaInfo.codeObj;
      FaceMetaInfo.value[key] = FaceMetaInfo.codeObj[value] || "";
    }
  }

  static getValue(key: "code" | "hint" | "score") {
    return FaceMetaInfo.value[key] ?? "";
  }
}

export default FaceMetaInfo;
