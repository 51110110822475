import React, { FC, useCallback } from 'react';
import {  Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    setOpen: any
    open: boolean;
    sessionId: string;
}

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			'simplici-onboarding': any;
		}
	}
}

export const SessionModal: FC<Props> = ({setOpen, open, sessionId}) => {
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen]);


  const style = {
    width: "100%",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none'
  };

  // const qrUrl = `https://secure.stage.satschel.com/664dd51f0baadc5b3b8fb52e?type=qr&instance=${instanceId}`

  return (
    <div>
      <Modal
        style={{
          width: "100%"
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
            <CloseIcon />
          </IconButton>
          <div
            className="BusinessSession--container--web-component"
            dangerouslySetInnerHTML={{
              __html: `<session-details
                  session_id="${sessionId}"
                  token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDVjOTI5MWI3ZjM5ZDVkYTI2OWQzNWMiLCJpYXQiOjE3MjU5ODk0NzN9.HNzuPUKiMOuNXNVxi5758SAy6OxrHEwv9GSJbLkHo3E"
                  comments="true"
                  env="beta"
                  type="sessions"
                  compliance_data="false"
                  action_disable="false"
                  session_type="complex"
                  is_pdf="true"
                  is_admin="false"
              />`,
            }}
        />
        </Box>
      </Modal>
    </div>
  );
}