import { EventTitle } from '../event-title/event-title';
import './event-header.scss';

interface IEventHeader {
	label: string;
	className?: string; // Made optional with default value
}

export const EventHeader = ({ label, className = '' }: IEventHeader) => {
	// Return null early if label is not provided
	if (!label) {
		return null;
	}

	// Combine base and additional class names
	const eventClass = `event-header-wrapper ${className.trim()}`; // Ensure no extra spaces

	return (
		<div className={eventClass}>
			<EventTitle title={label} />
		</div>
	);
};
