import { GestureRecognizerResult } from "@mediapipe/tasks-vision";

export const defaultGestureRecognizerResults: GestureRecognizerResult = {
    "gestures": [
        [
            {
                "score": 0,
                "index": -1,
                "categoryName": "Open_Palm",
                "displayName": ""
            }
        ],
        [
            {
                "score": 0,
                "index": -1,
                "categoryName": "Open_Palm",
                "displayName": ""
            }
        ]
    ],
    "handedness": [
        [
            {
                "score": 0.94940185546875,
                "index": 1,
                "categoryName": "Right",
                "displayName": "Right"
            }
        ],
        [
            {
                "score": 0.9404296875,
                "index": 0,
                "categoryName": "Left",
                "displayName": "Left"
            }
        ]
    ],
    "handednesses": [
        [
            {
                "score": 0,
                "index": 1,
                "categoryName": "Right",
                "displayName": "Right"
            }
        ],
        [
            {
                "score": 0,
                "index": 0,
                "categoryName": "Left",
                "displayName": "Left"
            }
        ]
    ],
    "landmarks": [],
    "worldLandmarks": []
  }