import './event-title.scss';

interface IEventTitle {
	title?: string; // Made optional to handle cases when it is not passed
}

export const EventTitle = ({ title }: IEventTitle) => {
	// Return null early if title is empty or not provided
	if (!title) {
		return null;
	}

	return <div className="event-title">{title}</div>;
};
