import React, { FC, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {  Modal, Box, IconButton, CircularProgress, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import {useRecoilState, useSetRecoilState} from 'recoil'
// import { SessionEnrollState, SessionDetailState } from './states';
import { ISession } from './types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import QRCode from "react-qr-code";
import { ISessionDetailsData } from './session-types';
import { useRecoilValue } from 'recoil';
import { QrState } from './states';
import { Memory } from './memory';

interface Props {
    setOpen: any
    open: boolean;
    instanceId: string;
}

// STAGE
// const token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY2MmFjOGU5YzE3MjlmNzIzNTk4ZWFhMiIsImNsaWVudF9pZCI6IjNmNDVlZDE5NzI3MjQzNDliNjBhMTUxYTIwMmU4NWZkIiwiYnVzaW5lc3MiOiI2M2MxNmQwZjlhZDQ3MjllZGM0NTQ3M2UiLCJpYXQiOjE3MTYzNzQwNTYsImV4cCI6MTc0NzQ3ODA1Nn0.pCohdj_0fc6sjBy0ikBcSn0-EtY9ziglQjLBONzY6-0'

// PROD
// export const token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY2ZDViZjBhNTNhZTI0OWRkMDNlYTRkOSIsImNsaWVudF9pZCI6ImI4YzcyNjhhYWQwYzQ2OWI5MGJjZmJlNDczNmVhNzkwIiwiYnVzaW5lc3MiOiI2NWY4NTFmYTFlMWMzYWQ5NzFhNjcxYmQiLCJpYXQiOjE3MjUyODQyNTQsImV4cCI6MTc1MTIwNDI1NH0.LOEZNL-CmyMkmBrNiGJzhU8gZhecYGQAXY_rD9KcXdE'

// BETA
export const token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY2ZTAyNGI0MTRiMDdiNTY0YTJhZmRiMCIsImNsaWVudF9pZCI6ImJjYzEyYzM3NmE0NTRlOWVhZjQ5ZThhODk2ZGRmY2I0IiwiYnVzaW5lc3MiOiI2NDVjOTI5MWI3ZjM5ZDVkYTI2OWQzNTkiLCJpYXQiOjE3MjU5NjU0OTIsImV4cCI6MTc1MTg4NTQ5Mn0.qr-wpf-X-SktnT2Aezhto4I5SiSQwlNZb-zs0g2hF_s'

const client = axios.create({
  headers: {
    Authorization: token
  }
});

// const HOST_URL = 'https://api.stage.satschel.com/v2';
export const HOST_URL = 'https://api.beta.satschel.com/v2';

// STAGE
// const businessId = '63c16d0f9ad4729edc45473e'

export const businessId = '645c9291b7f39d5da269d359'

export const getSessionDetail = async (instanceId: string) => {
  const {data: complexSessions} = await client.get(`${HOST_URL}/pipelines/complex-sessions?businessId=${businessId}&pagination=true`);
  const sessionList: ISession[] = complexSessions?.data?.[0]?.data
  if (sessionList) {
    const foundSession = sessionList.find(session => session.metadata?.instance === instanceId && session.status !== 'draft');
    if (foundSession) {
      const url = `${HOST_URL}/pipelines/complex-session-details/${foundSession?._id}?include=true`
      const {data} = await client.get<ISessionDetailsData>(url)
      return data?.data ?? {};
    }
  }
  return null;
}

export const getSessionDetailByPhone = async (phone: string) => {
  const {data: complexSessions} = await client.get(`${HOST_URL}/pipelines/complex-sessions?businessId=${businessId}`);
  const sessionList: ISession[] = complexSessions?.data?.[0]?.data ?? complexSessions?.data;
  if (sessionList) {
    const foundSession = sessionList.find(session => session.contact === phone && session.status === 'completed');
    if (foundSession) {
      const url = `${HOST_URL}/pipelines/complex-session-details/${foundSession?._id}?include=true`
      const {data} = await client.get<ISessionDetailsData>(url)
      return data?.data ?? {};
    }
  }
  return null;
}

export const getSessionDetailByEmail = async (email: string) => {
  const {data: complexSessions} = await client.get(`${HOST_URL}/pipelines/complex-sessions?businessId=${businessId}&pagination=true`);
  const sessionList: ISession[] = complexSessions?.data?.[0]?.data;
  if (sessionList) {
    const foundSession = sessionList.find(session => session.email === email && session.status === 'completed');
    if (foundSession) {
      const url = `${HOST_URL}/pipelines/complex-session-details/${foundSession?._id}?include=true`
      const {data} = await client.get<ISessionDetailsData>(url)
      return data?.data ?? {};
    }
  }
  return null;
}

export const getSessionDetailById = async (sessionId: string) => {
    const url = `${HOST_URL}/pipelines/complex-session-details/${sessionId}?include=true`
    const {data} = await client.get<ISessionDetailsData>(url)
    return data?.data ?? {};
}

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			'simplici-onboarding': any;
		}
	}
}

export const EventModalWithIframe: FC<Props> = ({setOpen, open, instanceId}) => {
  // const [sessionEnroll, setEnrollState] = useRecoilState(SessionEnrollState);
  // const setSessionDetail = useSetRecoilState(SessionDetailState);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const qrs = useRecoilValue(QrState);
  const [selectedQr, setSelectedQr] = useState<string>(qrs?.[0]?._id);
  
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen]);

  const handleChange = useCallback((event: SelectChangeEvent<string>) => {
    setSelectedQr(event.target.value as any)
  }, []);

  const onComplete = useCallback(() => {
    setCompleted(true);
    setLoading(false);
    setTimeout(() => {
      setOpen(false);
    }, 2000)
  }, [setOpen])

  useEffect(() => {
    const internal = setInterval(async() => {
      const sessionData = await getSessionDetail(instanceId);
        if (sessionData) {
          setLoading(true);
          if (sessionData.status === "completed") {
            onComplete();
            // setSessionDetail(sessionData)
            clearInterval(internal);
          }
        }
    }, 4000)
  }, [instanceId])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: '60vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none'
  };

  const getQr = useCallback((qrId: string) => {
    return `https://secure.beta.simplici.io/${qrId}?type=qr&instance=${instanceId}&qrId=${qrId}&eventId=${Memory.eventId}`
  }, [instanceId])


  console.log('qrs', qrs);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
            <CloseIcon />
          </IconButton>
          <FormControl className='oboardingflow-dropdown' fullWidth>
            <InputLabel id="demo-simple-select-label">Onboarding Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedQr}
              label="Onboarding Type"
              onChange={handleChange}
            >
              {qrs.map(qr => (<MenuItem key={qr?._id} value={qr?._id}>{qr?.name}</MenuItem>))}
            </Select>
          </FormControl>
            <div className='qr-container'>
              {
                selectedQr ? <QRCode value={getQr(selectedQr)} /> : null
              }
            </div>
            <div className='qr-container'>
              {
                loading ? 
                <div>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '2px'
                  }}>
                    <CircularProgress />
                  </div>
                  <div>Verfication Started</div>
                </div>
                : null
              }
              {
                completed ? 
                <div>
                   <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '2px'
                  }}>
                    <CheckCircleOutlineIcon style={{
                      width: "50px",
                      height: "50px",
                      color: 'green'
                    }} />
                  </div>
                  <div>Verfication Completed</div>
                </div> 
                : null
              }
            </div>
        </Box>
      </Modal>
    </div>
  );
}