import React, { useState, useEffect, FC } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useRecoilState } from 'recoil';
import { SelectedDeviceIdState } from './states';

interface Props {
    handleChange?: (deviceId: string) => void
}

export const CameraSelector: FC<Props> = () => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useRecoilState(SelectedDeviceIdState);

  useEffect(() => {
    // Get list of video input devices (cameras)
    const getCameraDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setDevices(videoDevices);
        if (videoDevices.length) {
          setSelectedDeviceId(videoDevices[0].deviceId)
        }
      } catch (error) {
        console.error('Error accessing camera devices:', error);
      }
    };

    getCameraDevices();
  }, []);

  const handleDeviceChange = async (event: any) => {
    const deviceId = event.target?.value;
    setSelectedDeviceId(deviceId);
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="camera-select-label">Select Camera</InputLabel>
        <Select
          labelId="camera-select-label"
          value={selectedDeviceId}
          onChange={handleDeviceChange}
          label="Select Camera"
          sx={{ height: 40 }}
          MenuProps={{
            PaperProps: {
              sx: { maxHeight: 200 }  // Optional: Adjust max height of the dropdown menu
            }
          }}
        >
          {devices.map(device => (
            <MenuItem key={device.deviceId} value={device.deviceId}>
              {device.label || `Camera ${device.deviceId}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
