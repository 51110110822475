import React, { useMemo } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { SessionDetailState } from './states';

function maskLast4Digits(str: string) {
  // Mask all but the last 4 digits
  return str.slice(0, -4).replace(/./g, '*') + str.slice(-4);
}

export function UserInfo() {
  const sessionDetail = useRecoilValue(SessionDetailState);
  const {userInfo, kyc: kycValue} = sessionDetail ?? {}
  const {kyc, ssn: SSNValue} = kycValue?.[0]?.data ?? {};
  const {basicInfo} = kyc ?? {};
  const defaultValue = '--'
  
  const phoneNumber =  userInfo?.phone ?? defaultValue
  const name = useMemo(() => {
    const {firstName, lastName} = basicInfo ?? {};
    if (!firstName && !lastName) {
      return defaultValue
    }
    return `${firstName?.value} ${lastName?.value}`
  }, [basicInfo]);

  console.log('basicInfo', basicInfo);
  const address = basicInfo?.fullAddress?.value || basicInfo?.address?.value || defaultValue
  const dob = basicInfo?.dateOfBirth?.value ?? defaultValue
  const ssn = SSNValue?.value ? maskLast4Digits(SSNValue.value) : defaultValue

  return (
    <Grid container maxWidth="sm" sx={{ marginTop: 1 }}>
      <Grid item sm={6}>
        <Typography variant="h6" gutterBottom>
          User Information
        </Typography>

        <Box sx={{ padding: 0, fontSize: '0.875rem' }}>
          <Typography variant="body2">
            <strong>Phone Number:</strong> {phoneNumber}
          </Typography>
          <Typography variant="body2">
            <strong>Name:</strong> {name}
          </Typography>
          <Typography variant="body2">
            <strong>Address:</strong> {address}
          </Typography>
          <Typography variant="body2">
            <strong>DOB:</strong> {dob}
          </Typography>
          <Typography variant="body2">
            <strong>SSN:</strong> {ssn}
          </Typography>
        </Box>
        </Grid>
        <Grid item sm={6}>
            <Typography style={{
              display: "flex",
              justifyContent: 'flex-end'
            }} variant="body1" gutterBottom>
              User Session Selfie
            </Typography>
            <img style={{
              width: "40%",
              float: "right"
            }} src={sessionDetail?.authentication?.[0].data.data.selfieImage} />
        </Grid>
  </Grid>
  );
}
