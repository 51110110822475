import {initializeApp} from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAX-qEF0bFHDOXNtyo6xeLvEKVMS8niAXs",
  authDomain: "glossy-fastness-305315.firebaseapp.com",
  databaseURL: "https://glossy-fastness-305315-default-rtdb.firebaseio.com",
  projectId: "glossy-fastness-305315",
  storageBucket: "glossy-fastness-305315.appspot.com",
  messagingSenderId: "952484154781",
  appId: "1:952484154781:web:05abe5e2da41633d247374"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };