import { DateTime } from 'luxon';
import { v4 } from 'uuid';

const createProfileTabs = <T extends ProfileTabValue>(
	tabs: ProfileTab<T>[]
): ProfileTab<T>[] => tabs;

export const PROFILE_TABS = createProfileTabs([
	{
		_id: v4(),
		label: 'All Invitee',
		value: 'allInvitee',
	},
	{
		_id: v4(),
		label: 'Attended',
		value: 'attended',
	},
	{
		_id: v4(),
		label: 'No Show',
		value: 'noShow',
	},
]);

export const PENDING_DUMMY_USERS = [
	{
		img: 'https://media.istockphoto.com/id/1961182478/photo/happy-young-woman-using-phone.jpg?s=2048x2048&w=is&k=20&c=NFFSYvt-8v-FPwN0Vyio9BqOL8fSnjMXnEXmYZf55tU=',
	},
	{
		img: 'https://media.istockphoto.com/id/1395110003/photo/beautiful-woman-in-kitchen-using-phone.jpg?s=612x612&w=0&k=20&c=sAlK3Y6zoyHJ_tKkV3LqLFiRXoF8lACa8128u5sNgTA=',
	},
	{
		img: 'https://media.istockphoto.com/id/2149798261/photo/portrait-of-cheerful-man-using-smartphone-to-read-news-or-scroll-social-networks.jpg?s=612x612&w=0&k=20&c=wgQYga4wv7L7iX2qK7j_uDJDxtuPEJ5EADP5nqfnyu4=',
	},
	{
		img: 'https://media.istockphoto.com/id/1468624221/photo/businessman-using-phone-for-online-payment.jpg?s=612x612&w=0&k=20&c=CnRlgJCbZAipg803lYzw41hWm1fy9g-ySX6C7rf1V9w=',
	},
	{
		img: 'https://media.istockphoto.com/id/1916797587/photo/a-smiling-young-adult-man-plans-a-vacation-trip-with-his-friends-over-text-messages-on-his.jpg?s=612x612&w=0&k=20&c=3ZVw1uy_QMOqb-n79dvXRmQ6d-tQ6FffYjeZpAP6O5k=',
	},
	{
		img: 'https://plus.unsplash.com/premium_photo-1671656349322-41de944d259b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D',
	},
	{
		img: 'https://plus.unsplash.com/premium_photo-1664541336896-b3d5f7dec9a3?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D',
	},
	{
		img: 'https://plus.unsplash.com/premium_photo-1669882305273-674eff6567af?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHVzZXJ8ZW58MHx8MHx8fDA%3D',
	},
	{
		img: 'https://media.istockphoto.com/id/2151279555/photo/business-woman-thinking-and-laptop-for-online-research-in-office-and-information-on-digital.webp?a=1&b=1&s=612x612&w=0&k=20&c=vdIzqNQ8zAaHIrMz1zHIAqQPGoXUBuRXjgPoNMmxD3Y=',
	},
	{
		img: 'https://media.istockphoto.com/id/1485794147/photo/young-woman-using-a-smart-phone-while-sitting-on-a-couch-in-a-living-room.webp?a=1&b=1&s=612x612&w=0&k=20&c=jUFQtqzEf7WQgzkR3mfOB3aTVuaqo-eXxeUqilTkK_s=',
	},
];

export const TAG_CSS: any = {
	declined: {
		color: '#EF4343',
		background: '#EF43430F',
	},
	vip: {
		color: '#7935DE',
		background: '#7935DE1F',
	},
	'club seats': {
		color: '#7935DE',
		background: '#7935DE1F',
	},
	'watch closely': {
		color: '#F38A20',
		background: '#F38A201F',
	},
	accepted: {
		color: '#389F75',
		background: '#389F750F',
	},
	invited: {
		color: '#515767',
		background: '#0000000F',
	},
	'deny entry': {
		color: '#EF4343',
		background: '#EF43430F',
	},
};

export const TAG_MAP: Record<string, string> = {
	PENDING: 'Invited',
	APPROVED: 'Accepted',
	REJECTED: 'Deny Entry',
}


export const getDateTime=(date: string)=>{
	const formattedDate = DateTime.fromISO(date).toFormat('EEEE, LLLL dd · h:mma');
	return formattedDate;
  }