import React, { FC, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {  Modal, Box, IconButton, CircularProgress, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import {useRecoilState, useSetRecoilState} from 'recoil'
// import { SessionEnrollState, SessionDetailState } from './states';
import { ISession, ISessionDetails } from './types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import QRCode from "react-qr-code";
import { ISessionDetailsData } from './session-types';

interface Props {
    setOpen: any
    open: boolean;
    instanceId: string;
}

// STAGE
// const token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY2MmFjOGU5YzE3MjlmNzIzNTk4ZWFhMiIsImNsaWVudF9pZCI6IjNmNDVlZDE5NzI3MjQzNDliNjBhMTUxYTIwMmU4NWZkIiwiYnVzaW5lc3MiOiI2M2MxNmQwZjlhZDQ3MjllZGM0NTQ3M2UiLCJpYXQiOjE3MTYzNzQwNTYsImV4cCI6MTc0NzQ3ODA1Nn0.pCohdj_0fc6sjBy0ikBcSn0-EtY9ziglQjLBONzY6-0'

// PROD
export const token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY2ZDViZjBhNTNhZTI0OWRkMDNlYTRkOSIsImNsaWVudF9pZCI6ImI4YzcyNjhhYWQwYzQ2OWI5MGJjZmJlNDczNmVhNzkwIiwiYnVzaW5lc3MiOiI2NWY4NTFmYTFlMWMzYWQ5NzFhNjcxYmQiLCJpYXQiOjE3MjUyODQyNTQsImV4cCI6MTc1MTIwNDI1NH0.LOEZNL-CmyMkmBrNiGJzhU8gZhecYGQAXY_rD9KcXdE'

const client = axios.create({
  headers: {
    Authorization: token
  }
});

// const HOST_URL = 'https://api.stage.satschel.com/v2';
export const HOST_URL = 'https://api.satschel.com/v2';

// STAGE
// const businessId = '63c16d0f9ad4729edc45473e'

export const businessId = '65f851fa1e1c3ad971a671bd'

export const getSessionDetail = async (instanceId: string) => {
  const {data: complexSessions} = await client.get(`${HOST_URL}/pipelines/complex-sessions?businessId=${businessId}&pagination=true`);
  const sessionList: ISession[] = complexSessions?.data?.[0]?.data
  if (sessionList) {
    const foundSession = sessionList.find(session => session.metadata?.instance === instanceId && session.status !== 'draft');
    if (foundSession) {
      const url = `${HOST_URL}/pipelines/complex-session-details/${foundSession?._id}?include=true`
      const {data} = await client.get<ISessionDetailsData>(url)
      return data?.data ?? {};
    }
  }
  return null;
}

export const getSessionDetailByPhone = async (phone: string) => {
  const {data: complexSessions} = await client.get(`${HOST_URL}/pipelines/complex-sessions?businessId=${businessId}`);
  const sessionList: ISession[] = complexSessions?.data?.[0]?.data ?? complexSessions?.data;
  if (sessionList) {
    const foundSession = sessionList.find(session => session.contact === phone && session.status === 'completed');
    if (foundSession) {
      const url = `${HOST_URL}/pipelines/complex-session-details/${foundSession?._id}?include=true`
      const {data} = await client.get<ISessionDetailsData>(url)
      return data?.data ?? {};
    }
  }
  return null;
}

export const getSessionDetailByEmail = async (email: string) => {
  const {data: complexSessions} = await client.get(`${HOST_URL}/pipelines/complex-sessions?businessId=${businessId}&pagination=true`);
  const sessionList: ISession[] = complexSessions?.data?.[0]?.data;
  if (sessionList) {
    const foundSession = sessionList.find(session => session.email === email && session.status === 'completed');
    if (foundSession) {
      const url = `${HOST_URL}/pipelines/complex-session-details/${foundSession?._id}?include=true`
      const {data} = await client.get<ISessionDetailsData>(url)
      return data?.data ?? {};
    }
  }
  return null;
}

export const getSessionDetailById = async (sessionId: string) => {
    const url = `${HOST_URL}/pipelines/complex-session-details/${sessionId}?include=true`
    const {data} = await client.get<ISessionDetailsData>(url)
    return data?.data ?? {};
}

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			'simplici-onboarding': any;
		}
	}
}

export const ModalWithIframe: FC<Props> = ({setOpen, open, instanceId}) => {
  // const [sessionEnroll, setEnrollState] = useRecoilState(SessionEnrollState);
  // const setSessionDetail = useSetRecoilState(SessionDetailState);
  const [loading, setLoading] = useState(false);
  const [onboardingType, setOnboardingType] = useState<'kyc' | 'auth'>('kyc');
  const [completed, setCompleted] = useState(false);
  
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen]);

  const handleChange = useCallback((event: SelectChangeEvent<"kyc" | "auth">) => {
    setOnboardingType(event.target.value as any)
  }, []);

  const onComplete = useCallback(() => {
    setCompleted(true);
    setLoading(false);
    setTimeout(() => {
      setOpen(false);
    }, 2000)
  }, [setOpen])

  useEffect(() => {
    const internal = setInterval(async() => {
      const sessionData = await getSessionDetail(instanceId);
        if (sessionData) {
          setLoading(true);
          if (sessionData.status === "completed") {
            onComplete();
            // setSessionDetail(sessionData)
            clearInterval(internal);
          }
        }
    }, 4000)
  }, [instanceId])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: '50vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none'
  };

  // const qrUrl = `https://secure.stage.satschel.com/664dd51f0baadc5b3b8fb52e?type=qr&instance=${instanceId}`

  const QRIds = ['66d5c0b15bcbfa0033b4f4c4', '66d5c0d8631827bda1499aea']

  const QR = {
    auth: `https://secure.simplici.io/${QRIds[0]}?type=qr&instance=${instanceId}&qrId=${QRIds[0]}`,
    kyc: `https://secure.simplici.io/${QRIds[1]}?type=qr&instance=${instanceId}&qrId=${QRIds[1]}`
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
            <CloseIcon />
          </IconButton>
          <FormControl className='oboardingflow-dropdown' fullWidth>
            <InputLabel id="demo-simple-select-label">Onboarding Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={onboardingType}
              label="Onboarding Type"
              onChange={handleChange}
            >
              <MenuItem value={'kyc'}>Auth + KYC</MenuItem>
              <MenuItem value={'auth'}>Auth Only</MenuItem>
            </Select>
          </FormControl>
            <div className='qr-container'>
              <QRCode value={QR[onboardingType]} />
            </div>
            <div className='qr-container'>
              {
                loading ? 
                <div>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '2px'
                  }}>
                    <CircularProgress />
                  </div>
                  <div>Verfication Started</div>
                </div>
                : null
              }
              {
                completed ? 
                <div>
                   <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '2px'
                  }}>
                    <CheckCircleOutlineIcon style={{
                      width: "50px",
                      height: "50px",
                      color: 'green'
                    }} />
                  </div>
                  <div>Verfication Completed</div>
                </div> 
                : null
              }
            </div>
        </Box>
      </Modal>
    </div>
  );
}