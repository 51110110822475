import React, { FC, useState } from "react";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";

interface Props {
  open: boolean;
  onClose: any;
  children: any;
}

const ScoreModal: FC<Props> = ({ open, onClose, children }) => {
    const handleCancel = () => {
        onClose();
    };
  return (
    <Modal
      open={open}
      onClose={handleCancel}
      aria-labelledby="phone-number-modal-title"
      aria-describedby="phone-number-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
         {children}
      </Box>
    </Modal>
  );
};

export default ScoreModal;
